<template>
  <div v-loading.fullscreen.lock="true"></div>
</template>

<script>
import { computed, onBeforeMount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "CallbackSpotify",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const redirectURL = computed(() => {
      return store.getters[
        "SpotifyAccessTokenHelperModule/getRedirectPathAfterLogin"
      ];
    });

    onBeforeMount(async () => {
      const code = route.query.code;
      if (code) {
        await store.dispatch(
          "SpotifyAccessTokenHelperModule/setAccessTokenByCode",
          code
        );
        if (redirectURL.value) {
          const redirectURLVar = redirectURL.value;
          await store.commit(
            "SpotifyAccessTokenHelperModule/SET_REDIRECT_PATH_AFTER_LOGIN",
            null
          );
          await router.push(redirectURLVar);
        } else {
          await router.push("/discover");
        }
      }
    });
  },
};
</script>

<style scoped></style>
